/* app.css */


body {
  font-family: 'Arial', sans-serif;
}


.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form {
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 15px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}


input[type="text"],
input[type="password"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure consistent box model */
}

input[type="password"],
select,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button,
.save-word-button,
.insight-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

button:hover,
.save-word-button:hover,
.insight-button:hover {
  background-color: #45a049;
}

button.disabled,
.save-word-button.disabled,
.insight-button.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.button-spacing {
  margin-right: 10px; /* Adjust the margin as needed */
}

.response-output {
  flex: 2;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #ddd;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.response-output .original {
  font-weight: bold;
  color: #007bff;
}

.response-output .transliteration {
  color: #6c757d;
  margin-left: 10px;
}

.response-output .translation {
  color: #495057;
  margin-left: 10px;
  font-style: italic;
}



@media (max-width: 768px) {
  .output-container {
    flex-direction: column;
  }

  .response-output {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .word-list {
    margin-left: 0;
    margin-top: 0;
  }
}

.insight-output {
  margin-top: 20px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #ddd;
  line-height: 1.6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}



h1 {
  font-size: 2.1rem; /* 2.5 times the root font size */
  color: #02351e;
}

h2 {
  font-size: 1.6rem; /* 1.6 times the root font size */
  color: #333;
  margin-top: 2px;  /* Reduce space above */
  margin-bottom: 4px; /* Reduce space below */
}

h3 {
  font-size: 1.4rem; /* 1.4 times the root font size */
  color: #333;
  margin-top: 10px;  /* Reduce space above the heading */
  margin-bottom: 15px; /* Reduce space below the heading */
}

.language-label {
  font-size: 1rem;
  color: #333;
  font-weight: bold; /* Optional if you want it bold */
  margin-right: 5px; /* Add space between "Language:" and the value */
}

label {
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}

textarea {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

select {
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}




.selected-text-buttons button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.selected-text-buttons button:hover {
  background-color: #45a049;
}

.selected-text-buttons .disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.navigation-arrows {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.navigation-arrows button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigation-arrows button:hover {
  background-color: #45a049;
}

.navigation-arrows button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}




.title-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures the logo is pushed to the right */
  padding: 5px;
  background-color: #ffffff; /* Example background color */
}

.title-header h1 {
  font-size: 2.1rem; /* 2.5 times the root font size */
  color: #02351e;
  margin: 0; /* Ensure no extra margin */
  flex-grow: 1; /* Allows the title to take up available space */
  margin-bottom: 10px;
}

.title-header button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.title-header button:hover {
  background-color: #45a049;
}

.title-header .register-button {
  margin-left: 10px;
}

.title-logo {
  height: 50px; /* Adjust size */
  margin-left: 15px; /* Spacing between title and logo */
  margin-bottom: 10px;
}



.about-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-container p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-container ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.about-container li {
  margin-bottom: 10px;
}



.trainer-container {
  display: flex;
  justify-content: space-between; /* Ensure space between form and words list */
  align-items: flex-start; /* Align items at the top */
}

.form-container {
  flex: 3; /* Form takes up more space */
  margin-bottom: 0; /* Remove any margin-bottom on the form container */
}




.word-list-container {
  margin-top: 30px;
  flex: 1;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 300px; /* Set a maximum width */
}

.word-list-container ul {
  list-style-type: none;
  padding: 0;
}

.word-list-container li {
  margin-bottom: 10px;
  line-height: 1;
}






.words-display-container {
  padding: 20px;
  margin-top: 20px;
}

.words-table {
  width: 100%;
  border-collapse: collapse;
}

.words-table th, .words-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.words-table th {
  background-color: #f4f4f4;
  text-align: left;
}





/* Container to align the text and toggle switch horizontally */
.more-options-container {
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 4px; /* Reduced space between text and toggle */
  margin-top: 10px; /* Add margin-top to slightly separate from the last form row */
}

.more-options-label {
  font-size: 14px; /* Adjust font size if needed */
  margin-right: 0; /* Remove extra space between label and toggle */
}

/* TOGGLE SLIDER SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;  /* Adjust width to make it smaller */
  height: 18px; /* Adjust height to make it smaller */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;  /* Smaller circle */
  width: 12px;   /* Smaller circle */
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(17px);  /* Adjust translate distance for the smaller size */
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}






/* Style for the navigation bar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
}

/* Align the logo, menu button, and title */
.navbar-left {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between logo, menu button, and title */
}

/* Style for the logo */
.title-logo {
  height: 50px;
  margin: 0; /* Remove extra margins */
  padding: 0; /* Remove any padding */
  vertical-align: middle; /* Align image to bottom */
}


/* Style for the title */
.navbar-title {
  font-size: 24px;
  font-weight: bold;
  color: #02351e;
  margin: 0; /* Remove extra margin */
  padding: 0; /* Remove padding */
}

/* Right-side container for login and register buttons */
.navbar-right {
  display: flex;
  align-items: center;
  gap: 5px; /* Space between login and register buttons */
}

.navbar-right button {
  margin-left: 10px;
}

.navbar-right span {
  margin-right: 15px;
  font-size: 16px;
}






/* Navigation button style */
.nav-button {
  display: flex;
  justify-content: center;
  align-items: center; /* Center icon inside the button */
  background-color: #4CAF50; /* Standard green color */
  color: white; /* White text color */
  border: none; /* Remove border */
  width: 50px; /* Match logo size */
  height: 50px; /* Match logo size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth color transition */
  margin: 0; /* Ensure no extra margin */
  padding: 0; /* Ensure no extra padding */
}



/* Dropdown menu style */
.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust to position below the navbar */
  left: 20px; /* Align with nav button */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it appears above other elements */
}

.dropdown-menu a {
  display: block;
  padding: 8px 10px;
  color: black;
  text-decoration: none;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}









/* WordDisplay.js */

/* Language folders container */
.language-folders {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Folder button styling */
.folder-button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  background-color: #999494;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.folder-button.active {
  background-color: #4CAF50;
  color: white;
}

/* Edit button styling */
.edit-button {
  margin-left: auto;
  padding: 8px 12px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.edit-button.edit-mode {
  background-color: #a63636;
}

/* Action buttons styling */
.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.action-buttons button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  background-color: #a63636;
  margin-bottom: 20px;
}

